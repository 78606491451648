@import "../../shared/styles/kit.module"
@import "src/shared/styles/breakpoints"

.epicDraw
  background: linear-gradient(180deg, black 0%, rgba(3, 7, 43, 0) 100%)
  padding-top: toRem(70)
  display: flex
  flex-direction: column
  align-items: center

.title
  width: 100%
  font-style: italic
  font-weight: 900
  font-size: toRem(48)
  line-height: toRem(48)
  color: white !important
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  text-transform: uppercase
  white-space: nowrap

.titleBreak
  width: 100%
  white-space: break-spaces
  text-align: center

.subTitle
  padding-top: toRem(70)
  color: #CCFB52 !important
  font-size: toRem(36)
  line-height: toRem(36)

.pressButton
  padding-top: toRem(50)
  font-size: toRem(30) !important
  line-height: toRem(30) !important

.button
  padding-top: toRem(28)

.text
  padding-top: toRem(44)
  text-transform: initial
  font-size: toRem(24)
  line-height: toRem(27)


@media (max-width: $mobilePhone)
  .epicDraw
     padding-top: toRem(0)
  .title
    font-size: toRem(22)
    line-height: toRem(22)
  .subTitle
    font-size: toRem(21)
    line-height: toRem(22)
  .text
    width: 100%
    white-space: break-spaces
    word-break: break-word
    text-align: center
    font-size: toRem(18)
    line-height: toRem(21)

@import "../../shared/styles/kit.module"
@import "../../shared/styles/breakpoints"

.roulette
    width: 100%
    left: 0
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    overflow: hidden
    margin-bottom: toRem(-85)
    background: linear-gradient(180deg, #020203 0%, rgba(7, 13, 65, 0) 30%)

    &__title
        position: relative
        z-index: 1
        margin-top: toRem(-5)

    &__image
        position: relative
        margin-top: toRem(-30)
        margin-left: toRem(10)
    &__main
        width: toRem(700)


@media (max-width: $mobilePhone)
    .roulette
        &__image
            margin-top: toRem(-10)
        &__up
            left: toRem(170)
            top: toRem(80)
            width: toRem(100)
        &__main
            width: toRem(360)

@import "../../styles/kit.module"
@import "../../styles/breakpoints"

.ladderPlaceholder
    width: fit-content
    margin: 0 auto
    font-style: italic
    font-weight: 900
    font-size: toRem(78)
    line-height: toRem(78)
    text-align: left
    text-transform: uppercase
    color: #CCFB52
    display: flex
    flex-direction: column

    &__secondary
        margin-left: 10%
        white-space: nowrap

@media (max-width: $mobilePhone)
    .ladderPlaceholder
        font-size: toRem(28)
        line-height: toRem(28)

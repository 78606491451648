@import "../../shared/styles/kit.module"
@import "../../shared/styles/breakpoints"

.roulette
    overflow: hidden
    position: relative
    padding-top: toRem(80)
    &__title
        font-size: toRem(80) !important
        position: relative
        right: toRem(-50)
    &__image
        display: flex
        justify-content: center
        width: 100%
        margin-top: toRem(-50) !important
    &__main
        width: 70%
    &__up
        top: toRem(230)
        left: toRem(300)
        position: absolute
        width: toRem(1100)
    &__info
        margin-top: toRem(-70)
        text-align: center
        color: white
        font-size: toRem(26)

@media (max-width: $mobilePhone)
    .roulette
        margin-top: toRem(50)
        overflow: hidden
        &__title
            z-index: 1
            margin: 0 !important
            font-size: toRem(25) !important
        &__image
            margin-top: toRem(-50) !important
        &__main
            width: 150%
            position: relative
        &__up
            z-index: 3
            top: toRem(133)
            left: toRem(10)
            width: toRem(300)
        &__info
            font-size: toRem(16)
            margin-top: 0



@import "../../styles/kit.module"
@import "../../styles/breakpoints"

.button
    text-transform: uppercase
    box-sizing: border-box
    width: toRem(270)
    height: toRem(50)
    background: linear-gradient(180deg, rgba(37, 216, 255, 0.2) 0%, rgba(0, 255, 71, 0) 100%), #CCFB52
    border: 1px solid #CCFB52
    box-shadow: toRem(0) toRem(5) toRem(54) rgba(53, 138, 133, 0.75)
    border-radius: toRem(25)
    transition: all 0.5s ease
    font-size: toRem(20)

    &:hover
        background: linear-gradient(180deg, rgba(54, 172, 101, 0.2) 0%, rgba(0, 255, 71, 0) 100%), #FFFFFF
        border: 1px solid #FFFFFF
        box-shadow: toRem(0) toRem(5) toRem(54) rgba(111, 207, 201, 0.75)
        cursor: pointer


    &__content
        font-style: normal
        font-weight: 400
        line-height: toRem(24)
        text-align: center
        letter-spacing: -0.05em
        color: #082603

@media (max-width: $mobilePhone)
    .button
        width: toRem(180)
        height: toRem(40)
        font-size: toRem(16)

        &__content
            line-height: toRem(19)
@import "../../shared/styles/kit.module"
@import "../../shared/styles/breakpoints"

.subFooter
     padding-top: toRem(40)
     padding-bottom: toRem(40)
     color: white
     display: flex
     justify-content: space-between
     margin: 0 auto
     & > *
          min-width: 33%
     &__politics
          text-align: center
          justify-content: center
          display: flex
          gap: toRem(15)
     &__allRight
          text-align: right

@media (max-width: $mobilePhone)
     .subFooter
          font-size: toRem(6)

@import "../../shared/styles/kit.module"
@import "../../shared/styles/breakpoints"

.footer
    padding: toRem(200) toRem(50) toRem(10) toRem(50)
    background: linear-gradient(180deg, rgba(7, 13, 65, 0) 0%, #020203 100%)

    &__logo
        display: flex
        justify-content: center
        margin-top: toRem(33)
    &__social
        display: flex
        gap: 15px
        width: fit-content
        margin: 0 auto

@media (max-width: $mobilePhone)
    .footer
        padding: toRem(100) toRem(10) toRem(100) toRem(10)
        position: absolute
        z-index: 2
        height: toRem(60)
        background: none
        &__logo
            margin-top: toRem(24)

@import "../../shared/styles/kit.module"
@import "src/shared/styles/breakpoints"

.moreLoot
  display: flex
  flex-direction: column
  align-items: center
.title
  width: 100%
  font-style: italic
  font-weight: 900
  font-size: toRem(48)
  line-height: toRem(48)
  color: white !important
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  text-transform: uppercase
  white-space: nowrap

.subTitle
  padding-top: toRem(90)
  color: #CCFB52 !important
  font-size: toRem(30)
  line-height: toRem(32)

.pressButton
  padding-top: toRem(50)
  font-size: toRem(30) !important
  line-height: toRem(30) !important

.button
  padding-top: toRem(48)

.text
  padding-top: toRem(28)
  text-transform: initial
  font-size: toRem(24)
  line-height: toRem(27)

.mobileDescription
  display: none
.descDescription
  display: flex

@media (max-width: $mobilePhone)
  .mobileDescription
    display: flex
  .descDescription
    display: none
  .epicDraw
    padding-top: toRem(0)
  .title
    font-size: toRem(22)
    line-height: toRem(22)
  .subTitle
    font-size: toRem(21)
    line-height: toRem(22)
  .text
    width: 100%
    padding-top: toRem(28)
    font-size: toRem(18)
    line-height: toRem(21)

@import "../../shared/styles/kit.module"
@import "../../shared/styles/breakpoints"

.win
    display: flex
    flex-direction: column
    align-items: center
    margin-top: toRem(100)
    &__info
        margin-top: toRem(15)
        color: #fff
        font-weight: 900
        font-style: italic
        font-size: toRem(36)
    &__button
        margin-top: toRem(36)
    &__add
        margin-top: toRem(15)
        font-size: toRem(16)
        color: white

@media (max-width: $mobilePhone)
    .win
        &__info
            font-size: toRem(16)
            text-align: center
        &__add
            font-size: toRem(10)

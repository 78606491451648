@import "../../shared/styles/kit.module"
@import "../../shared/styles/breakpoints"

.wrapper
  width: 100%
  height: toRem(137)
  background: linear-gradient(180deg, rgba(7, 13, 65, 0) 0%, #020203 100%)

.logo
  display: flex
  justify-content: center
  margin-top: toRem(33)

@media (max-width: $mobilePhone)
  .wrapper
    position: absolute
    z-index: 2
    height: toRem(60)
    background: none

  .logo
    margin-top: toRem(24)
@import "../../shared/styles/kit.module"
@import "../../shared/styles/breakpoints"

.banner
    background: #020203
    width: 100%
    display: flex
    justify-content: space-between

    &__video
        width: 70%
        margin-left: auto
        top: -1px

    &__image
        margin-top: toRem(16)
        width: toRem(838)
        height: toRem(100)

    &__info
        align-items: center
        position: absolute
        z-index: 3
        display: flex
        flex-direction: column
        margin-top: 5%
        margin-left: 10%

    &__button
        margin-top: toRem(90)
        scale: 1.5
        font-size: toRem(22) !important
        border-radius: toRem(50) !important

    &__videoControls
        width: toRem(1500)
        left: 20%
        position: absolute
        transition: all 0.2s ease
        z-index: 1

    &__volumeControls
        transition: .2s all
        padding-top: toRem(605)
        justify-content: end
        display: flex
        opacity: 0
        z-index: 2

        &:hover
            opacity: 1

    &__svg
        width: 28px
        height: 28px
        fill: #fff
        stroke: #fff
        cursor: pointer

    &__hidden
        display: none

    &__volumeButton
        border: none
        background-color: transparent


@media (max-width: $mobilePhone)
    .banner
        position: relative

        &__info
            margin-top: 40%
            margin-left: 15%

        &__button
            width: toRem(180) !important
            margin-top: toRem(50)
            scale: 0.9
            font-size: toRem(16) !important
            border-radius: toRem(50)

        &__video
            display: none

        &__image
            margin-top: toRem(-16)
            margin-left: 5%
            width: toRem(254)
            height: toRem(87)

        &__imageBackground
            width: 100%
            height: toRem(438)
            background-image: url(../../assets/images/mobile-banner.png)
            background-repeat: no-repeat
            background-size: cover

        &__button
            margin-top: toRem(27)

        &__videoControls
            display: none

@import "../../shared/styles/kit.module"
@import "src/shared/styles/breakpoints"

.background
  background-image: url("../../assets/images/whatIsEver/Color Bomb Shadow desk.png")
  background-size: cover
  background-repeat: no-repeat
  background-position: initial
  padding: 0 toRem(162)

.whatIsEver
  padding-top: toRem(10)


.whatTitle
  padding-top: toRem(60)
  & span
    &:first-child
      margin-right: 7%
    &:last-child
      margin-right: 7%

.desktopTags
  display: block
.mobileTags
  display: none

.title
  width: 100%
  font-style: italic
  font-weight: 900
  font-size: toRem(48)
  line-height: toRem(48)
  color: white !important
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  text-transform: uppercase
  white-space: nowrap
  & span
    &:last-child
      margin-left: 10%

.subTitle
  padding-top: toRem(70)
  color: #CCFB52 !important
  font-size: toRem(36)
  line-height: toRem(36)
  & span
    &:last-child
      margin-left: 8% !important

.tag
  color: #CCFB52 !important
  font-size: toRem(24) !important
  & span
    &:nth-child(2)
      font-size: toRem(74) !important


.tags
  display: flex
  padding-top: toRem(36)
  width: toRem(1000)
  margin: 0 auto

.prizeTag
  width: 100%
  font-style: italic
  font-weight: 900
  font-size: toRem(48)
  line-height: toRem(48)
  color: #CCFB52
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center

.pay
  font-size: toRem(24)
  line-height: toRem(27)
  margin-left: 6%
.billion
  display: flex
  justify-content: center
  align-items: center
  padding: toRem(10) 0
.bilEver
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  line-height: toRem(40)
  & span
    font-size: toRem(38)
    &:last-child
      margin-left: 40%

.bil1
  font-size: toRem(84)

.prize
  text-transform: uppercase
  font-size: toRem(24)
  line-height: toRem(27)
  margin-left: 19%

.compete
  display: flex
  align-items: center
  justify-content: center
  padding-top: toRem(136)
.description
  width: 28.2rem
  display: flex
  align-items: flex-start
  flex-direction: column

.bag
  width: toRem(291)
.competeLine
  border-bottom: 1px solid #CCFB52
  width: 100%
.competeText
  padding: 0 !important
  margin-left: toRem(96)
  align-items: flex-start
  margin-bottom: toRem(4)
.competeSubText
  margin-left: toRem(96)
  align-items: flex-start
  font-size: toRem(18)
  line-height: toRem(22)
  margin-top: toRem(6)
  color: white


.freeTournaments
  display: flex
  align-items: center
  justify-content: center
  padding-top: toRem(94)

.freeTournamentsDescription
  width: 43.2rem
  display: flex
  align-items: center
  flex-direction: column
.freeTournamentsText
  padding: 0 !important
  margin: 0 toRem(96) toRem(4) toRem(96)
  align-items: flex-start
.freeTournamentsTextSubText
  align-items: flex-start
  width: toRem(368)
  font-size: toRem(18)
  line-height: toRem(22)
  margin-top: toRem(6)
  color: white

.freeTournamentsLine
  border-bottom: 1px solid #CCFB52
  width: 100%
.cup
  width: toRem(346)


.helmet
  width: toRem(440)

.winText
  padding: 0 !important
  align-items: flex-start
  margin-bottom: toRem(4)
  margin-left: toRem(24)
  & span
    &:last-child
      margin-left: 18% !important

.winSubText
  margin-left: toRem(24)
  margin-right: toRem(96)
  align-items: flex-start
  font-size: toRem(18)
  line-height: toRem(22)
  margin-top: toRem(6)
  color: white


.planet
  width: toRem(247)
  margin-left: toRem(27)

.playText
  padding: 0 !important
  margin: 0 toRem(96) toRem(4) toRem(96)
  align-items: flex-start
  & span
    &:last-child
      margin-left: 15% !important


.play_button
  display: flex
  justify-content: center


@media (max-width: $mobilePhone)

  .whatIsEver
    padding-top: toRem(47)

  .desktopTags
    display: none
  .mobileTags
    padding-top: toRem(100)
    display: block


  .tags
    display: flex
    flex-direction: column
    width: 0
    gap: toRem(32)

  .subTitle
    font-size: toRem(21)
    line-height: toRem(22)

  .tag
    & span
      &:first-child
        margin-right: 10% !important
      &:last-child
        margin-left: 20% !important

  .prize
    margin-left: 23% !important

  .background
    background-image: url("../../assets/images/whatIsEver/Color Bomb Shadow mobile.png")
    background-repeat: no-repeat
    padding: 0 toRem(24) toRem(96) toRem(24)
    background-position: bottom
    background-size: auto

  .whatTitle
    padding-top: 0
    font-size: toRem(28)
    line-height: toRem(28)
    & span
      &:first-child
        margin-right: 20% !important


  .competeMobile
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    padding-bottom: toRem(64)
    &:last-child
      padding-bottom: 0

  .competeMobileImage
    width: toRem(224)

  .competeMobileText
    padding-top: toRem(5) !important
    margin-left: 4% !important
    & span
      &:first-child
        margin-right: 30% !important
  .freeTournamentsMobileText
    padding-top: toRem(15) !important
  .planetMobileText
    padding-top: toRem(15) !important
  .helmetMobileText
    padding-top: toRem(15) !important

  .competeMobileDescription
    text-align: center

  .competeMobileSubText
    color: white
    padding-top: toRem(8)
    padding-bottom: 1.75rem

  .freeTournamentsMobileImage
    width: toRem(267)

  .helmetMobileImage
    width: toRem(338)

  .planetMobileImage
    width: toRem(191)

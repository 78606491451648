@import "shared/styles/kit.module"
@import "src/shared/styles/breakpoints"

body
  background: #03072B
  margin: 0
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  font-family: Inter, monospace

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button
  -webkit-appearance: none

input[type='number'],
input[type="number"]:hover,
input[type="number"]:focus
  appearance: none
  -moz-appearance: textfield

a
  color: inherit
  text-decoration: none
  img
    transition: scale .1s ease-in-out
    scale: 1
  &:hover
    img
      scale: 1.05

code
  font-family: Inter, monospace

html
  font-size: .83334vw

@media (max-width: $mobilePhone)
  html
    font-size: 4.26vw

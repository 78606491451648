@import "../../shared/styles/kit.module"
@import "src/shared/styles/breakpoints"

.separator
    padding-top: toRem(100)
    display: flex
    justify-content: center
    align-items: center
    gap: toRem(7)
    margin: 0 toRem(162)

.line
    height: toRem(1)
    width: 100%
    margin-bottom: toRem(3)
    background-color: white


@media (max-width: $mobilePhone)
    .separator
        margin: 0
